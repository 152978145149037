import BaseService from './BaseService';
import axios from './api';

class ConversationService extends BaseService {
  constructor() {
    super('/conversations');
  }

  async markAsRead(conversationId) {
    return axios.post(`${this.endpoint}/${conversationId}/mark_as_read`);
  }
}

export default new ConversationService();
